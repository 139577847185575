import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'

export default function ValidationTestProcedureTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <p>Testing Location: {frontmatter.testLocation}</p>
      <h2 className="title is-2">Overview</h2>
      <h3 className="title is-3">Lot Selection</h3>
      <Table table={frontmatter.lotSelectionTable} />
      <h3 className="title is-3">Sampling</h3>
      <p>
        Validation procedures ensure the device will fulfill user needs and will
        be tested on a “Pass” or “Fail” criteria. Based on Validation Procedures
        (Doc ID 10043) section 5.5.3.1, the parameters being tested are{' '}
        {frontmatter.riskLevel} risk. Due to the repeatability of testing, we
        expect there to be zero failures requiring a minimum of{' '}
        {frontmatter.minimumNumberOfSamples} samples.If product has visual
        discrepancies or fails validation testing, then Validation Procedures
        (Doc ID 10043) section 5.5.3.3 should be referenced for additional
        sampling guidelines.
      </p>
      <h3 className="title is-3">Required Materials</h3>
      <Table table={frontmatter.requiredMaterialsTable} />
      <h2 className="title is-2">Validation Tests</h2>
      <h3 className="title is-3">User Needs</h3>
      <table>
        {frontmatter.userNeeds.map(function (row, i) {
          return (
            <tr>
              <td>[{i + 1}]</td>
              <td>{row}</td>
            </tr>
          )
        })}
      </table>
      <h3 className="title is-3">Procedure</h3>
      <p>
        NOTE: (#) – Correspond with Results Table. Should be placed when
        procedure states “Record results in (#)” [#] – Corresponds with User
        Need that is being fulfilled with that step. Occurs at the end of the
        step.
      </p>
      <table>
        {frontmatter.procedures.map(function (row, i) {
          return (
            <tr>
              <td>[{i + 1}]</td>
              <td>{row}</td>
            </tr>
          )
        })}
      </table>
      <h3 className="title is-3">Results</h3>
      <Table table={frontmatter.resultsTable} />
      <h2 className="title is-2">Signatures</h2>
      <strong>Tests Performed By:</strong>
      <br />
      <strong>Tester Qualification:</strong>
      <br />
      <hr></hr>
      <pre>Signature Date</pre>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryValidationTestProcedure($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        author
        productTitle

        testLocation
        lotSelectionTable {
          partNumber
          lotNumber
          datePackaged
          dateSterilized
        }
        riskLevel
        minimumNumberOfSamples
        requiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        userNeeds
        procedures
        resultsTable {
          DUT
          SecurelyAttachingDrapes
          LowerBodyProtection
          Cleaning
          Removal
        }
      }
    }
  }
`
